import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Button } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description'; // 导入一个文本图标，例如文档图标
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // 导入 UserContext
import NetworkManager from './NetworkManager';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import static_BG from './assets/StatisticIMGs/static_BG.png';
import recordsAnalyzer from './RecordAnalyzer';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import MedicoLogo from './assets/MedicoLogoBlue120.svg';
import { DateTime } from 'luxon';
import ApneaIndexComponent from './ApneaIndexMergedImage';

// import { ApneaEventType } from './NetworkManager';  // 假设路径正确
function DiagnoseReportPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const reportTimePair = location.state?.reportTimePair;
  const { currentUser, isSuperAdmin, adminSearchUserId, userProfile, generatedAudioReport, setGeneratedAudioReport } = useContext(UserContext);

  const [isFechingRecords, setIsFechingRecords] = useState(true);
  // const [error, setError] = useState(null);
  // const [wavFileDownloaded, setWavFileDownloaded] = useState(false);
  // const [wavFileContents, setWavFileContents] = useState(null);
  // const [ECGFileContents, setECGFileContents] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(null);

  // const apneaIndexValue =
  //   analysisResult.reiIndex !== null &&
  //     analysisResult.reiIndex !== undefined
  //     ? Math.min(Math.floor(analysisResult.reiIndex), 45) // 保留整数并限制最大值为45
  //     : '-2'; // 默认值

  const handleBack = () => {
    navigate(-1); // 导航回上一个页面
  };
  const handlePDFBTNOnClick = async (generatedAudioReport) => {
    const doc = new jsPDF('p', 'mm', 'a4'); // 创建PDF文档，竖向，单位mm，大小A4
    const margin = 10; // 边距10mm
    const pdfWidth = doc.internal.pageSize.getWidth() - 2 * margin; // 减去左右边距

    const footerText1 = '';
    const footerText2 = 'Page 1 of 3';
    // 渲染第一个div (PDF1)
    const pdf1 = document.getElementById('PDF1');
    const imgData1 = await generateImageData(pdf1, doc, pdfWidth);
    if (imgData1) {
      doc.addImage(imgData1, 'jpeg', margin, 10, pdfWidth, getPDFHeight(imgData1, pdfWidth, doc)); // 调整图像位置和大小
      // 添加页脚到第一页
      doc.setFontSize(10);
      doc.text(footerText1, margin, doc.internal.pageSize.getHeight() - margin);
      doc.text(footerText2, doc.internal.pageSize.getWidth() - margin - doc.getTextWidth(footerText2), doc.internal.pageSize.getHeight() - margin);
    }

    // 添加新的一页
    doc.addPage();
    const footerText3 = 'Page 2 of 3';
    // 渲染第二个div (PDF2)
    const pdf2 = document.getElementById('PDF2');
    const imgData2 = await generateImageData(pdf2, doc, pdfWidth);
    if (imgData2) {
      doc.addImage(imgData2, 'jpeg', margin, 10, pdfWidth, getPDFHeight(imgData2, pdfWidth, doc)); // 调整图像位置和大小
      // 添加页脚到第二页
      doc.text(footerText1, margin, doc.internal.pageSize.getHeight() - margin);
      doc.text(footerText3, doc.internal.pageSize.getWidth() - margin - doc.getTextWidth(footerText3), doc.internal.pageSize.getHeight() - margin);
    }




    // 渲染第3个div (PDF3)
    const pdf3 = document.getElementById('PDF3');
    if (pdf3) {
      const imgData3 = await generateImageData(pdf3, doc, pdfWidth);
      if (imgData3 && imgData3.startsWith('data:image/jpeg;base64,')) {
        // 添加新的一页
        doc.addPage();
        const footerText4 = 'Page 3 of 3';
        doc.addImage(imgData3, 'jpeg', margin, 10, pdfWidth, getPDFHeight(imgData3, pdfWidth, doc)); // 调整图像位置和大小
        // 添加页脚到第二页
        doc.text(footerText1, margin, doc.internal.pageSize.getHeight() - margin);
        doc.text(footerText4, doc.internal.pageSize.getWidth() - margin - doc.getTextWidth(footerText4), doc.internal.pageSize.getHeight() - margin);
      }
    } else {
      console.log('PDF3 element not found, skipping...');
    }






    // 保存PDF文件
    doc.save('diagnose.pdf');
  };
  const generateImageData = async (element, doc, pdfWidth) => {
    try {
      const canvas = await html2canvas(element, { backgroundColor: "#ffffff" });
      const imgData = canvas.toDataURL('image/jpeg');
      return imgData;
    } catch (error) {
      console.error('Error generating image data:', error);
      return null;
    }
  };
  // Helper: 根据图像数据计算PDF高度
  const getPDFHeight = (imgData, pdfWidth, doc) => {
    const imgProps = doc.getImageProperties(imgData);
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    return pdfHeight;
  };





  // 调试用日志
  console.log('Rendering DiagnoseReportPage with:', {
    currentUser,
    reportTimePair
  });
  console.log('Type of analysisResult:', typeof analysisResult);
  console.log('Value of analysisResult:', analysisResult);
  useEffect(() => {
    console.log('[DiagnosePage] useEffect triggered with dependencies:', { currentUser, reportTimePair, setGeneratedAudioReport, isSuperAdmin, adminSearchUserId });

    if (!currentUser) {
      // setError('user is not log in')
      // console.log(error)
      return
    }


    setIsFechingRecords(true);
    const fetchRecordFilesPromise = NetworkManager.fetchUserRecordsData((adminSearchUserId == null || adminSearchUserId.length === 0) ? currentUser.uid : adminSearchUserId, reportTimePair)
      .then(records => {
        return records;
      })
      .catch(error => {
        console.log('Error fetching records:', error);
        return 'error'; // 临时的，以便你知道这里发生了错误
      });
    // 确定要使用的用户ID
    const userId = isSuperAdmin && adminSearchUserId ? adminSearchUserId : currentUser.uid;

    const fetchAiResultPromise = NetworkManager.fetchUserAiResultFilesMetaData(userId, [reportTimePair])
      .then(results => {
        const result = results[0]; // 因为我们只查询了一个时间对
        if (result.status === 'not_found') {
          console.log('AI result not found for time pair');
          throw new Error('AI result not found for time pair');
        } else if (result.status === 'processing') {
          console.log('AI result is still processing');
          return null;
        } else if (result.status === 'success') {
          const AiResultURL = result.data;
          console.log('AI result URL:', AiResultURL);
          return fetch(AiResultURL)
            .then(response => {
              if (!response.ok) {
                throw new Error('Failed to fetch AI result file from cloud storage');
              }
              return response.text(); // 将内容解析为文本
            })
            .then(fileContents => {
              // 打印下载的文件内容
              // console.log('Downloaded file contents:', fileContents);
              // 如果需要更新进度，可以在这里调用 updateOverallProgress
              return fileContents; // 返回文件内容
            });
        } else {
          throw new Error('Unexpected status in AI result response');
        }
      })
      .catch(error => {
        console.error('An error occurred:', error);
      });


    const fetchSnoreResultPromise = NetworkManager.fetchUserSnoreResultFilesMetaData(userId, [reportTimePair])
      .then(fileContents => {
        if (fileContents) {
          console.log('Downloaded snore result file contents:', fileContents);
        } else {
          console.log('Snore result not available yet.');
        }
      })
      .catch(error => {
        console.error('Error fetching snore result:', error);
      });

    const startTime = reportTimePair[0];
    const endTime = reportTimePair[1];
    const baseUrl = NetworkManager.baseUrl;
    // 构建完整的 fileURLs
    const fileURLs = [
      `${baseUrl}/o/userOverviewPNG/${userId}/${startTime}-${endTime}/plot_body_pos.png`,
      `${baseUrl}/o/userOverviewPNG/${userId}/${startTime}-${endTime}/plot_spo2_hr.png`
    ];

    const fetchPNGFilesPromise = NetworkManager.fetchMultipleCloudStorageFileFromBackend(userId, fileURLs)
      .then(files => {
        if (!files || files.length === 0) {
          console.log('No files found for the provided URLs');
          throw new Error('No files found for the provided URLs');
        }

        // 使用 Promise.all 并行处理每个文件，并将文件名与文件 Blob 绑定在一起
        return Promise.all(files.map((fileBlob, index) => {
          const fileURL = fileURLs[index];

          return {
            fileBlob,         // 文件的 Blob
            fileName: fileURL.split('/').pop()  // 提取文件名，比如 plot_body_pos.png 或 plot_spo2_hr.png
          };
        }));
      })
      .then(allProcessedFiles => {
        console.log('All PNG files have been processed successfully');
        return allProcessedFiles;
      })
      .catch(error => {
        console.error('An error occurred during the file fetching or processing:', error);
      });

    Promise.all([fetchRecordFilesPromise, fetchAiResultPromise, fetchSnoreResultPromise, fetchPNGFilesPromise])
      .then(([filteredRecords, aiAudioReseult, snoreReseult, PNGFiles]) => {
        console.log('RECORDS and airesult downloaded successfully.');
        // 此时直接使用 wavFiles, ecgFiles, records，因为它们是Promise返回的最新值
        const startTime_query = reportTimePair[0];
        const endTime_query = reportTimePair[1];
        let generatedReport = {
          reportStartTime: startTime_query,
          reportEndTime: endTime_query,
          PCM_Files: null,   // WAV 文件内容，直接使用Promise的结果
          ECG_Files: null,   // ECG 文件内容，直接使用Promise的结果
          AiAudioResult: aiAudioReseult,//audio result breath number 1715968218-1715969393.txt
          snoreReseult: snoreReseult,
          Data_Records: filteredRecords, // 筛选后的记录，直接使用Promise的结果
          PNGFiles: PNGFiles
        };

        // const analysisResult = recordsAnalyzer.analyzeRecords(generatedReport.Data_Records, aiAudioReseult);
        // setAnalysisResult(analysisResult);
        console.log('Generated report:', generatedReport);

        let analysisResult;
        try {
          // 确保 analyzeRecords 返回的值是对象
          analysisResult = recordsAnalyzer.analyzeRecords(generatedReport.Data_Records, aiAudioReseult, snoreReseult);

          // 检查是否被意外转换为字符串
          if (typeof analysisResult === 'string') {
            console.warn('analysisResult is a string. Parsing it back to object.');
            analysisResult = JSON.parse(analysisResult); // 如果是字符串，解析为对象
          }

          console.log('Generated analysisResult:', analysisResult);
        } catch (error) {
          console.error('Error analyzing records:', error);
          analysisResult = null; // 或设置为默认值
        }

        if (analysisResult && typeof analysisResult === 'object') {
          setAnalysisResult(analysisResult); // 确保传递的是对象
        } else {
          console.error('Invalid analysisResult:', analysisResult);
        }
        // 提取分析结果中的 apneaEvents
        const apneaEvents = analysisResult.apneaHyponeaEvents;
        const sortedapneaHyponeadesaturationEvents = analysisResult.sortedapneaHyponeadesaturationEvents
        if (apneaEvents && apneaEvents.length > 0) {
          // 执行生成 Apnea 图像的逻辑

          const fetchGeneratedApneaIMGPromise = NetworkManager.generateApneaIMG(userId, generatedReport.reportStartTime, generatedReport.reportEndTime, sortedapneaHyponeadesaturationEvents)
            .then(({ imageBlob, imageUrl }) => {
              if (!imageBlob) {
                console.log('No image generated from the provided apnea events');
                throw new Error('No image generated from the provided apnea events');
              }

              // 生成并绑定 Blob URL 和文件名
              return {
                fileBlob: imageBlob,    // 将 Blob 数据绑定
                imageUrl: imageUrl,
                fileName: 'apnea_image.png'  // 设置文件名，比如生成的图像名
              };
            })
            .then(processedFile => {
              console.log('Apnea image has been processed successfully');
              return processedFile;
            })
            .catch(error => {
              console.error('An error occurred during the apnea image generation or processing:', error);
            });

          // 在需要时等待 Promise 完成
          fetchGeneratedApneaIMGPromise.then(apneaIMG => {
            // 可以在此处使用生成的 Apnea 图像数据
            console.log('Processed Apnea Image:', apneaIMG);
            generatedReport.apneaIMG = apneaIMG;
            // 使用 setGeneratedAudioReport 更新上下文中的 generatedAudioReport
            setGeneratedAudioReport((prevReport) => {
              if (JSON.stringify(prevReport) !== JSON.stringify(generatedReport)) {
                return generatedReport;
              }
              return prevReport; // 避免重复更新
            });
            // setError(null);
            setIsFechingRecords(false);
          });


        }
        else {
          setGeneratedAudioReport((prevReport) => {
            if (JSON.stringify(prevReport) !== JSON.stringify(generatedReport)) {
              return generatedReport;
            }
            return prevReport; // 避免重复更新
          });
          // setError(null);
          setIsFechingRecords(false);
        }
      })
      .catch(error => {
        console.error('An error occurred:', error);
        // setError('Error downloading files:', error);
        setIsFechingRecords(false);
      });
  }, [reportTimePair, isSuperAdmin, adminSearchUserId, currentUser, setGeneratedAudioReport]);

  // const formatBirthDate = (birthDate) => {
  //   if (!birthDate || !birthDate._seconds) return '';
  //   // 创建一个新的Date对象
  //   const date = new Date(birthDate._seconds * 1000); // 注意：JavaScript的Date对象使用毫秒
  //   // 格式化日期，例如：1980-12-01
  //   return date.toLocaleDateString().split('T')[0];
  // };
  const formatBirthDate = (birthDate, timeZone) => {
    if (!birthDate || !birthDate._seconds) return '';

    // 如果没有提供 timeZone，则使用默认值 "America/Los_Angeles"
    const timeZoneToUse = timeZone || 'America/Los_Angeles';

    // 将 Unix 时间戳（秒级）转换为 luxon 的 DateTime 对象，并使用指定时区
    const dateInTimeZone = DateTime.fromSeconds(birthDate._seconds).setZone(timeZoneToUse);

    // 格式化日期为 MM/dd/yy，例如：09/16/24
    return dateInTimeZone.toFormat('MM/dd/yyyy');
  };
  const formatDate = (birthDate) => {
    if (!birthDate) return '';
    // 创建一个新的Date对象
    const date = new Date(birthDate);
    // 格式化日期，例如：1980-12-01
    return date.toLocaleDateString().split('T')[0];
  };
  const formatDate2 = (dateUnix) => {
    if (!dateUnix) return '';

    // 创建一个新的 Date 对象
    const date = new Date(dateUnix);

    // 格式化日期
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZoneName: 'short'
    };

    const formattedDate = date.toLocaleString('en-US', options);

    // 将时间中的逗号去掉，并添加括号
    const formattedDateWithTimezone = formattedDate.replace(',', '').replace(/([A-Z]{3,4})$/, ' ($1)');

    return formattedDateWithTimezone;
  };
  const calculateAge = (birthDate) => {
    if (!birthDate || !birthDate._seconds) return null;
    // 创建一个新的 Date 对象
    const birth = new Date(birthDate._seconds * 1000);
    const now = new Date();

    let age = now.getFullYear() - birth.getFullYear();
    const monthDiff = now.getMonth() - birth.getMonth();
    const dayDiff = now.getDate() - birth.getDate();

    // 如果当前月份还没到生日月份，或者在生日月份但生日还没到，年龄减一岁
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age;
  };
  if (isFechingRecords) {
    return (
      <div style={{ marginTop: '217px' }}>
        <Container component="main" maxWidth={false} style={{ padding: '40px' }}>
          <Button
            onClick={handleBack}
            variant="contained"
            color="primary"
            style={{

              margin: '20px',
              backgroundColor: '#1976d2',
              color: 'white',
              fontSize: 'larger'
            }}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <h1>Loading......</h1>
        </Container>

      </div>
    )
  }
  else {
    return (
      <div style={{ marginTop: '217px' }}>
        <Container component="main" maxWidth={false} style={{ padding: '40px 40px 240px 40px' }}>
          <Button
            onClick={handleBack}
            variant="contained"
            color="primary"
            style={{
              margin: '20px',
              backgroundColor: '#1976d2',
              color: 'white',
              fontSize: 'larger'
            }}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          {/* 病人简历 Section */}
          <div id='Page1'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <p style={{ fontSize: '50px', margin: '0' }}>
                {userProfile.firstName + " " + userProfile.lastName}
              </p>
              <div>
                <h2>DOB :</h2>
                <p style={{ fontSize: '30px', margin: '0' }}>
                  {formatBirthDate(userProfile.birthDate, userProfile.birthTimeZone)}
                </p>
              </div>
            </div>
            <Typography
              variant="h1"
              gutterBottom
              style={{
                // fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '30px',
                textAlign: 'left',
                color: '#fff',
                background: '#0E4969',
                height: '50px',
                padding: '10px',
                lineHeight: '50px', // 设置与容器高度一致减去padding的lineHeight
              }}
            >Recording details</Typography>
            <table style={{
              width: '90%', // 表格宽度自动填充父元素
              borderCollapse: 'collapse', // 去掉表格的外边框和竖线
              fontSize: '22px',
              lineHeight: '2.5',
              marginright: '26px',
            }}>
              <tbody>
                <tr>
                  <td colSpan="5" style={{ borderBottom: '1px solid #ccc' }}>Device:</td>
                  <td colSpan="2" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'center', fontWeight: 'bold' }}>VitalMark</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}><div>Type:</div></td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>MK-I</td>
                </tr>
                <tr>
                  <td style={{ borderBottom: '1px solid #ccc' }}>Recording:</td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Date:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.RecordStartDate}</td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Start:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordStartTime}</td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>End:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordEndTime}</td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Duration - hr:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordHours}</td>
                </tr>
                {/* <tr>
                  <td style={{ borderBottom: '1px solid #ccc' }}>Monitoring time (flow):</td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}></td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}></td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Start:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordStartTime}</td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>End:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordEndTime}</td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Duration - hr:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordHours}</td>
                </tr>
                <tr>
                  <td style={{ borderBottom: '1px solid #ccc' }}>Oxygen saturation evaluation:</td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}></td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}></td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Start:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordStartTime}</td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>End:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordEndTime}</td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Duration - hr:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordHours}</td>
                </tr> */}
              </tbody>
            </table>
            <Typography
              variant="h1"
              gutterBottom
              style={{
                // fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '30px',
                textAlign: 'left',
                color: '#fff',
                background: '#0E4969',
                height: '50px',
                padding: '10px',
                lineHeight: '50px', // 设置与容器高度一致减去padding的lineHeight
              }}
            >Statistics</Typography>
            {/* <img
              src={static_BG} alt="static_BG"
              style={{ width: '80%' , margin:'20px' }}
            /> */}

            {analysisResult && analysisResult.reiIndex !== null ? (
              <ApneaIndexComponent analysisResult={analysisResult} />
            ) : (
              <p>Loading...</p>
            )}
            <table style={{
              width: '90%', // 表格宽度自动填充父元素
              borderCollapse: 'collapse', // 去掉表格的外边框和竖线
              fontSize: '22px',
              lineHeight: '2.5',
              marginright: '26px',
            }}>
              <tbody>
                <tr>
                  <td colSpan="3" style={{ borderBottom: '1px solid #ccc' }}>Events Index:</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>REI (AHI):</td>
                  {/* <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>REI (AHI):</td>REI (AHI): 呼吸暂停低通气指数（Respiratory Event Index 或 Apnea-Hypopnea Index），每小时睡眠中的呼吸暂停和低通气事件的平均次数。这里为12.0，意味着平均每小时有12次呼吸暂停或低通气事件。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.reiIndex !== null && analysisResult.reiIndex !== undefined ? analysisResult.reiIndex : 'Processing'}</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> AI:</td>
                  {/* AI: 呼吸暂停指数（Apnea Index），每小时的完全呼吸暂停事件数。这里为1.4。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.apneaIndex !== null && analysisResult.apneaIndex !== undefined ? analysisResult.apneaIndex : 'Processing'}</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> HI:</td>
                  {/* HI: 低通气指数（Hypopnea Index），每小时的部分呼吸减少事件数。这里为10.6。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.hypopneaIndex !== null && analysisResult.hypopneaIndex !== undefined ? analysisResult.hypopneaIndex : 'Processing'}</td>
                </tr>
                <tr>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc' }}>Supine:</td>
                  {/* 仰卧 */}
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Time - hr:</td>
                  {/* Time - hr: 在该体位下睡眠的时间，这里是3小时51分钟，占总睡眠时间的60.2%。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.totalSurpinTime} ({analysisResult.totalSurpinPercent}%)</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>REI (AHI):</td>
                  {/* REI (AHI)、AI、HI: 仰卧位时的相关指数，分别为18.4、2.1和16.4，显示仰卧位时呼吸障碍事件更频繁。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.SurpinReiCountIndex !== null && analysisResult.SurpinReiCountIndex !== undefined ? analysisResult.SurpinReiCountIndex : 'Processing'}</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> AI:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.SurpinApneaCountIndex !== null && analysisResult.SurpinApneaCountIndex !== undefined ? analysisResult.SurpinApneaCountIndex : 'Processing'}</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> HI:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.SurpinHypopneaCountIndex !== null && analysisResult.SurpinHypopneaCountIndex !== undefined ? analysisResult.SurpinHypopneaCountIndex : 'Processing'}</td>
                </tr>
                <tr>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc' }}>Non-supine:</td>
                  {/* 非仰卧 */}
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Time - hr:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.totalUnSurpinTime} ({analysisResult.totalUnSurpinPercent}%)</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>REI (AHI):</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.UnSurpinReiCountIndex !== null && analysisResult.UnSurpinReiCountIndex !== undefined ? analysisResult.UnSurpinReiCountIndex : 'Processing'}</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> AI:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.UnSurpinApneaCountIndex !== null && analysisResult.UnSurpinApneaCountIndex !== undefined ? analysisResult.UnSurpinApneaCountIndex : 'Processing'}</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> HI:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.UnSurpinHypopneaCountIndex !== null && analysisResult.UnSurpinHypopneaCountIndex !== undefined ? analysisResult.UnSurpinHypopneaCountIndex : 'Processing'}</td>
                </tr>
                <tr>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc' }}>Upright:</td>
                  {/* 非仰卧 */}
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Time - hr:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.totalUpRightTime} ({analysisResult.totalUpRightPercent}%)</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>REI (AHI):</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.UpRightReiCountIndex !== null && analysisResult.UpRightReiCountIndex !== undefined ? analysisResult.UpRightReiCountIndex : 'Processing'}</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> AI:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.UpRightApneaCountIndex !== null && analysisResult.UpRightApneaCountIndex !== undefined ? analysisResult.UpRightApneaCountIndex : 'Processing'}</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> HI:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.UpRightHypopneaCountIndex !== null && analysisResult.UpRightHypopneaCountIndex !== undefined ? analysisResult.UpRightHypopneaCountIndex : 'Processing'}</td>
                </tr>
                <tr>
                  <td colSpan="5" style={{ borderBottom: '1px solid #ccc' }}>Events totals:</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> Apneas:</td>
                  {/* Apneas: 呼吸暂停总次数，这里为9次。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}> {analysisResult.apneaCount !== null && analysisResult.apneaCount !== undefined ? analysisResult.apneaCount : 'Processing'}</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> Hypopneas:</td>
                  {/* Hypopneas: 低通气总次数，这里为68次。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}> {analysisResult.hypopneaCount !== null && analysisResult.hypopneaCount !== undefined ? analysisResult.hypopneaCount : 'Processing'}</td>
                </tr>
                {/* <tr>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc' }}>Apnea index:</td>
                        //Apnea index (呼吸暂停指数): 进一步细分呼吸暂停的类型，包括阻塞性（0.8）、中枢性（0.6）、混合性（0.0）和未分类（0.0）。 
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Obstructive:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>0 </td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Central:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>0</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> Mixed:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>0</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> Unclassified:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>0</td>
                      </tr>
                      <tr>
                        <td colSpan="5" style={{ borderBottom: '1px solid #ccc' }}>Cheyne-Stokes respiration:</td>
                        //Cheyne-Stokes respiration: 钟摆呼吸，是一种呼吸模式，表现为呼吸逐渐加深后又逐渐变浅，最后停止，然后循环重复。这里记录的时间为0小时，百分比为0%，意味着没有观察到此呼吸模式。 
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> Time - hr:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>0</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> Percentage:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>0</td>
                      </tr> */}
                <tr>
                  <td style={{ height: '2em' }} colSpan="9"></td>
                </tr>
                <tr>
                  <td colSpan="5" style={{ borderBottom: '1px solid #ccc' }}>Oxygen desaturation:</td>
                  {/* Oxygen Desaturation (氧气饱和度下降) */}
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>ODI:</td>
                  {/* ODI (氧饱和度下降指数): 每小时睡眠期间氧饱和度下降3%或以上的次数，这里为11.2次。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.ODI}</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> Total:</td>
                  {/* Total (总数): 记录期间氧饱和度下降的总次数，这里为72次 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.ODITotal}</td>
                </tr>
                <tr>
                  <td colSpan="3" style={{ borderBottom: '1px solid #ccc' }}>Oxygen saturation %:</td>
                  {/* Oxygen Saturation % (氧气饱和度百分比) */}
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Baseline:</td>
                  {/* Baseline (基线): 平均氧饱和度的基线值，这里为97%。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.modeSPO2AsBaseline === 0 ? "No Valid Data" : analysisResult.modeSPO2AsBaseline} </td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Avg:</td>
                  {/* Avg (平均值): 平均氧饱和度百分比，这里为95%。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.averageSPO2 <= 0 ? "No Valid Data" : analysisResult.averageSPO2} </td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> Lowest:</td>
                  {/* Lowest (最低值): 记录期间最低的氧饱和度百分比，这里为83%。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.minSPO2Value === Infinity || analysisResult.minSPO2Value === -Infinity ? 'No Valid Data' : analysisResult.minSPO2Value}</td>
                </tr>
                <tr>
                  <td colSpan="3" style={{}}>Oxygen saturation - eval time %:</td>
                  {/* Oxygen Saturation - Eval Time % (氧饱和度评估时间百分比) */}
                  <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>≤90%sat:</td>
                  {/* ≤90%sat: 氧饱和度低于90%的时间百分比，这里为1%。 */}
                  <td style={{ fontWeight: 'bold' }}> {isNaN(analysisResult.SPO2percentBelow90) ? 'No Valid Data' : analysisResult.SPO2percentBelow90} </td>
                  <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>≤85%sat:</td>
                  {/* ≤85%sat: 氧饱和度低于85%的时间百分比，这里为0%。 */}
                  <td style={{ fontWeight: 'bold' }}> {isNaN(analysisResult.SPO2percentBelow85) ? 'No Valid Data' : analysisResult.SPO2percentBelow85}</td>
                  <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>≤80%sat:</td>
                  {/* ≤80%sat: 氧饱和度低于80%的时间百分比，这里为0%。 */}
                  <td style={{ fontWeight: 'bold' }}> {isNaN(analysisResult.SPO2percentBelow80) ? 'No Valid Data' : analysisResult.SPO2percentBelow80}</td>
                </tr>
                <tr>
                  <td colSpan="6" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>≤88%sat:</td>
                  {/* ≤88%sat: 氧饱和度低于88%的时间百分比，也是0%。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}> {isNaN(analysisResult.SPO2percentBelow88) ? 'No Valid Data' : analysisResult.SPO2percentBelow88}</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>≤88%Time - hr:</td>
                  {/* ≤88%Time - hr: 氧饱和度低于88%的总时间，这里为0小时0分钟。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.SPO2below88TotalTimeHours}</td>
                </tr>
                <tr>
                  <td style={{ height: '2em' }} colSpan="9"></td>
                </tr>
                <tr>
                  <td colSpan="3" style={{ borderBottom: '1px solid #ccc' }}>Breaths:</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Total:</td>
                  {/* Total (总数): 总呼吸次数，这里为5324次。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{typeof analysisResult.totalBreaths === 'number' ? Math.round(analysisResult.totalBreaths) : analysisResult.totalBreaths} </td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Avg/min:</td>
                  {/*  (平均每分钟): 平均每分钟的呼吸次数，这里为13.9次。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{typeof analysisResult.totalBreaths === 'number' ? Math.round(analysisResult.averageBreathsPerMinute) : analysisResult.averageBreathsPerMinute}</td>
                  {/* <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Snores:</td>
                  // Snores (打鼾次数): 记录期间的打鼾总次数，这里为1296次。
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>0</td> */}
                </tr>
                <tr>
                  <td colSpan="3" style={{ borderBottom: '1px solid #ccc' }}>Snores:</td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Total:</td>
                  {/* Total (总数): 总呼吸次数，这里为5324次。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{typeof analysisResult.snoreCount === 'number' ? Math.round(analysisResult.snoreCount) : analysisResult.snoreCount} </td>
                  <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Avg/min:</td>
                  {/*  (平均每分钟): 平均每分钟的呼吸次数，这里为13.9次。 */}
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{typeof analysisResult.snoreAvgmin === 'number' ? Math.round(analysisResult.snoreAvgmin) : analysisResult.snoreAvgmin}</td>
                  {/* <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Snores:</td>
                  // Snores (打鼾次数): 记录期间的打鼾总次数，这里为1296次。
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>0</td> */}
                </tr>
                <tr>
                  <td colSpan="3" style={{}}>Pulse - bpm:</td>
                  {/* Pulse - bpm (心率 - 每分钟心跳次数) */}
                  <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>Min:</td>
                  {/* Min (最小值): 最低心率，这里为55次/分钟。 */}
                  <td style={{ fontWeight: 'bold' }}>{analysisResult.minBPMValue === Infinity || analysisResult.minBPMValue === -Infinity ? 'No Valid Data' : analysisResult.minBPMValue} </td>
                  <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>Avg:</td>
                  {/* Avg (平均值): 平均心率，这里为69次/分钟。 */}
                  <td style={{ fontWeight: 'bold' }}>{isNaN(analysisResult.averageBPM) ? 'No Valid Data' : analysisResult.averageBPM}</td>
                  <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>Max:</td>
                  {/* Max (最大值): 最高心率，这里为97次/分钟。 */}
                  <td style={{ fontWeight: 'bold' }}>{analysisResult.maxBPMValue === Infinity || analysisResult.maxBPMValue === -Infinity ? 'No Valid Data' : analysisResult.maxBPMValue}</td>
                </tr>
                <tr>
                  <td colSpan="3" style={{}}>Body temperature °C:</td>

                  <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>Min:</td>

                  <td style={{ fontWeight: 'bold' }}>{analysisResult.minTempValue === Infinity || analysisResult.minTempValue === -Infinity ? 'No Valid Data' : analysisResult.minTempValue} </td>
                  <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>Avg:</td>

                  <td style={{ fontWeight: 'bold' }}>{isNaN(analysisResult.averageBodyTemp) ? 'No Valid Data' : analysisResult.averageBodyTemp}</td>
                  <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>Max:</td>

                  <td style={{ fontWeight: 'bold' }}>{analysisResult.maxTempValue === Infinity || analysisResult.maxTempValue === -Infinity ? 'No Valid Data' : analysisResult.maxTempValue}</td>
                </tr>
                <tr>
                  <td style={{ height: '2em' }} colSpan="9"></td>
                </tr>
                <tr>
                  <td colSpan="9" style={{}}>Analysis status: <span style={{ fontWeight: 'bold' }}>AI scoring</span></td>
                </tr>
                <tr>
                  <td colSpan="9" style={{}}>Analysis guidelines: <span style={{ fontWeight: 'bold' }}>AASM 2012</span></td>
                </tr>
                {/* <tr>
                  <td colSpan="9" style={{}}>Apnea[NaN%; NaNs; NaNs; NaNs; NaN%; NaN%; NaN%]; Hypopnea[NaN%; NaNs; NaNs; NaNs]; Snoring[NaN%; NaNs, NaNs; NaNs]; Desaturation[NaN%]; CSR[NaN]. Airflow sensor and respiratory effort sensor: Pressure transducer. Hypopneas were scored only if there was valid oximetry data.</td>
                </tr> */}
              </tbody>
            </table>
          </div>
          {generatedAudioReport.apneaIMG && (
            <div className="image-container" style={{ textAlign: "center" }}>
              <img
                src={generatedAudioReport.apneaIMG.imageUrl}  // 使用 imageUrl 渲染图片
                alt={generatedAudioReport.apneaIMG.fileName}  // 使用 fileName 作为图片的 alt 文本
                style={{
                  display: "block",
                  margin: "0 auto",  // 水平居中
                  width: "100%",     // 宽度占100%
                  marginBottom: "20px"  // 下方留空白
                }}
              />
            </div>
          )}
          {generatedAudioReport.PNGFiles && generatedAudioReport.PNGFiles.length > 0 && (
            <div className="image-container" style={{ textAlign: "center" }}>
              {generatedAudioReport.PNGFiles.map(({ fileBlob, fileName }) => {
                const imageUrl = window.URL.createObjectURL(fileBlob);

                return (
                  <img
                    key={fileName}
                    src={imageUrl}
                    alt={fileName}
                    style={{
                      display: "block",
                      margin: "0 auto",  // 水平居中
                      width: "100%",     // 宽度占100%
                      marginBottom: fileName === 'plot_body_pos.png' ? "20px" : "0",  // 给上方图片下方留空白
                      marginTop: fileName === 'plot_spo2_hr.png' ? "20px" : "0"      // 给下方图片上方留空白
                    }}
                  />
                );
              })}
            </div>
          )}

          <h1>Reports : </h1>
          <Button
            onClick={() => handlePDFBTNOnClick(generatedAudioReport)}
            variant="contained"
            endIcon={<DescriptionIcon />}
            style={{
              background: 'linear-gradient(0deg, #999999, #dddddd)', // 灰色渐变背景
              borderRadius: '10px', // 圆角矩形
              color: 'black', // 按钮文字颜色
              padding: '10px 18px', // 按钮内边距
              textTransform: 'none', // 防止文字自动大写
              fontSize: '20px'
            }}
          >
            Detailed Diagnostic Report PDF
          </Button>
          <div id='Page2'>
            <h1>Interpretation : </h1>
            <div id="interpretation-display-view" style={{ fontSize: '20px', lineHeight: '1.5' }}>
              Cc: Sleep Report
              <br />
              Referring Physician: Dr. xxxx xxxx
              <br />
              Other Recipient:
              <br />
              <br />
              The overall Apnea/Hypopnea Index (AHI) was 0 events/hour.
              <br />
              The overall Oxygen Desaturation Index (ODI) was 0 events/hour with an Oxygen nadir of 0%.
              <br />
              <br />
              Consultation Remarks:
              <br />
              Reason for referral: snoring.
              <br />
              The patient reported symptoms of snoring, dry mouth, witnessed apneas and daytime fatigue.
              <br />
              STOPBANG score was 0.
              <br />
              BMI was 0 kg/m2.
              <br />
              <br />
              RPSGT comment:
              <br />
              Acceptable Study. Periods of oximetry artifact, but overall a technically valid study.
              <br />
              Sleep Report Scored by: xxx xxxx, xxx, Registry No. xxx
              <br />
              <br />
              Impression:
              <br />
              {/* • Mild obstructive sleep apnea with moderate oxygen desaturation. Positional component with increased events with supine sleep. The patient can be offered PAP device, including an automatic titrating device. Alternative approved treatment options include regular exercise with weight loss, positional therapy to avoid the supine position, consideration for an oral mandibular advancement device and/or improving upper airway patency. */}
              <br />
              {/* • A follow up compliance report is recommended once stable on therapy to ensure efficacy, compliance and tolerance with therapy. */}
              <br />
              {/* • Clinical follow up will be with the referring physician. Referral to a sleep physician should be considered if there is difficulty establishing good control or persistent symptoms. */}
              <br />
              <br />
              Sincerely,
              <br />
              Dr. xxx xxx
              <br />
              MD, xxxx
              <div id="prescription-required-message" style={{ display: 'none' }}>
                <strong>
                  Prescription for therapy required
                </strong>
              </div>
            </div>
            <h1>Signature : </h1>
            <table style={{
              width: '61.8%', // 表格宽度自动填充父元素
              borderCollapse: 'collapse', // 去掉表格的外边框和竖线
              fontSize: '22px',
              lineHeight: '1.5',
              margin: '26px',
            }}>
              <tbody>
                <tr>
                  <td colSpan="1" style={{ fontWeight: 'bold' }}>xxxxxx (xxxxxx@gmail.com) </td>
                  <td colSpan="1" style={{ fontWeight: 'bold' }}>DR. xxxxxx xxxxxx </td>
                </tr>
                <tr>
                  <td colSpan="1" style={{ fontWeight: 'bold' }}>NPI xxxxx </td>
                  <td colSpan="1" style={{ fontWeight: 'bold' }}>xxx - xxxx xxxx Avenue </td>
                </tr>
                <tr>
                  <td colSpan="1" style={{ fontWeight: 'bold' }}>Signed 0 at 0 (PST) </td>
                  <td colSpan="1" style={{ fontWeight: 'bold' }}>Kelowna, British Columbia xxx xxx </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Container>
        <div id="content-to-pdf" style={{ position: 'absolute', top: '-9999px', left: '-9999px', width: '2048px' }}>
          {/* <div id="content-to-pdf"> */}
          <Container component="main" maxWidth={false} style={{ padding: '40px 40px 40px 40px' }}>

            {/* 病人简历 Section */}
            <div id='PDF1'>
              <div style={{ display: 'flex' }}>
                <img src={MedicoLogo} alt="Medico Logo" style={{ height: '200px', marginRight: '40px', position: 'absolute', top: '50px', left: '20px' }} />
                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                    <div style={{ height: '300px', textAlign: 'right' }} >
                      <h2 style={{ textAlign: 'right', fontSize: '38px', color: '#0192d8' }}>
                        {userProfile.firstName + " " + userProfile.lastName}
                      </h2>
                      <h2 style={{ textAlign: 'right', fontSize: '32px' }}>{formatDate(Date.now())}</h2>
                    </div>
                  </div>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderTop: '2px solid black',
                    borderBottom: '2px solid black',
                    padding: '10px',
                    marginTop: '20px',
                    marginBottom: '50px'
                  }}>
                    <p style={{ fontSize: '28px', lineHeight: '8px' }}>
                      DOB: {formatBirthDate(userProfile.birthDate, userProfile.birthTimeZone)}
                    </p>
                    <p style={{ fontSize: '28px', lineHeight: '8px' }}>
                      Age: {calculateAge(userProfile.birthDate)}
                    </p>
                    <p style={{ fontSize: '28px', lineHeight: '8px' }}>
                      Gender: {userProfile.gender}
                    </p>
                    <p style={{ textAlign: 'right', fontSize: '28px', lineHeight: '8px' }}>
                      BMI: {userProfile.BMI != null ? userProfile.BMI : 'N/A'}
                    </p>
                  </div>
                  <h1 style={{
                    backgroundColor: '#0E4969',
                    color: 'white',
                    padding: '10px',
                    display: 'flex',
                    height: '75px',
                    justifyContent: 'space-between',
                    width: '100%',
                    boxSizing: 'border-box', // 包含 padding 在内
                    overflow: 'hidden',      // 防止内容溢出
                    whiteSpace: 'nowrap'     // 防止内容换行
                  }}>
                    <span>Recording details</span>
                    <span>{formatDate(Date.now())}</span>
                  </h1>
                  <table style={{
                    width: '100%', // 表格宽度自动填充父元素
                    borderCollapse: 'collapse', // 去掉表格的外边框和竖线
                    fontSize: '28px',
                    lineHeight: '2.5',
                  }}>
                    <tbody>
                      <tr>
                        <td colSpan="5" style={{ borderBottom: '1px solid #ccc' }}>Device:</td>
                        <td colSpan="2" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'center', fontWeight: 'bold' }}>VitalMark</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}><div>Type:</div></td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>MK-I</td>
                      </tr>
                      <tr>
                        <td style={{ borderBottom: '1px solid #ccc' }}>Recording:</td>
                        <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Date:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.RecordStartDate}</td>
                        <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Start:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordStartTime}</td>
                        <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>End:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordEndTime}</td>
                        <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Duration - hr:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordHours}</td>
                      </tr>
                      {/* <tr>
                  <td style={{ borderBottom: '1px solid #ccc' }}>Monitoring time (flow):</td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}></td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}></td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Start:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordStartTime}</td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>End:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordEndTime}</td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Duration - hr:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordHours}</td>
                </tr>
                <tr>
                  <td style={{ borderBottom: '1px solid #ccc' }}>Oxygen saturation evaluation:</td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}></td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}></td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Start:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordStartTime}</td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>End:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordEndTime}</td>
                  <td style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Duration - hr:</td>
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.recordHours}</td>
                </tr> */}
                    </tbody>
                  </table>
                  <h1 style={{
                    backgroundColor: '#0E4969',
                    color: 'white',
                    padding: '10px',
                    display: 'flex',
                    height: '75px',
                    justifyContent: 'space-between',
                    width: '100%',
                    boxSizing: 'border-box', // 包含 padding 在内
                    overflow: 'hidden',      // 防止内容溢出
                    whiteSpace: 'nowrap'     // 防止内容换行
                  }}>
                    <span>Statistics</span>
                  </h1>
                  {analysisResult && analysisResult.reiIndex !== null ? (
                    <ApneaIndexComponent analysisResult={analysisResult} />
                  ) : (
                    <p>Loading...</p>
                  )}

                  <table style={{
                    width: '100%', // 表格宽度自动填充父元素
                    borderCollapse: 'collapse', // 去掉表格的外边框和竖线
                    fontSize: '28px',
                    lineHeight: '2.5',
                  }}>
                    <tbody>
                      <tr>
                        <td colSpan="3" style={{ borderBottom: '1px solid #ccc' }}>Events Index:</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>REI (AHI):</td>
                        {/* <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>REI (AHI):</td>REI (AHI): 呼吸暂停低通气指数（Respiratory Event Index 或 Apnea-Hypopnea Index），每小时睡眠中的呼吸暂停和低通气事件的平均次数。这里为12.0，意味着平均每小时有12次呼吸暂停或低通气事件。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.reiIndex !== null && analysisResult.reiIndex !== undefined ? analysisResult.reiIndex : 'Processing'}</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> AI:</td>
                        {/* AI: 呼吸暂停指数（Apnea Index），每小时的完全呼吸暂停事件数。这里为1.4。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.apneaIndex !== null && analysisResult.apneaIndex !== undefined ? analysisResult.apneaIndex : 'Processing'}</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> HI:</td>
                        {/* HI: 低通气指数（Hypopnea Index），每小时的部分呼吸减少事件数。这里为10.6。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.hypopneaIndex !== null && analysisResult.hypopneaIndex !== undefined ? analysisResult.hypopneaIndex : 'Processing'}</td>
                      </tr>
                      <tr>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc' }}>Supine:</td>
                        {/* 仰卧 */}
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Time - hr:</td>
                        {/* Time - hr: 在该体位下睡眠的时间，这里是3小时51分钟，占总睡眠时间的60.2%。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.totalSurpinTime} ({analysisResult.totalSurpinPercent}%)</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>REI (AHI):</td>
                        {/* REI (AHI)、AI、HI: 仰卧位时的相关指数，分别为18.4、2.1和16.4，显示仰卧位时呼吸障碍事件更频繁。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.SurpinReiCountIndex !== null && analysisResult.SurpinReiCountIndex !== undefined ? analysisResult.SurpinReiCountIndex : 'Processing'}</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> AI:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.SurpinApneaCountIndex !== null && analysisResult.SurpinApneaCountIndex !== undefined ? analysisResult.SurpinApneaCountIndex : 'Processing'}</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> HI:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.SurpinHypopneaCountIndex !== null && analysisResult.SurpinHypopneaCountIndex !== undefined ? analysisResult.SurpinHypopneaCountIndex : 'Processing'}</td>
                      </tr>
                      <tr>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc' }}>Non-supine:</td>
                        {/* 非仰卧 */}
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Time - hr:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.totalUnSurpinTime} ({analysisResult.totalUnSurpinPercent}%)</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>REI (AHI):</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.UnSurpinReiCountIndex !== null && analysisResult.UnSurpinReiCountIndex !== undefined ? analysisResult.UnSurpinReiCountIndex : 'Processing'}</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> AI:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.UnSurpinApneaCountIndex !== null && analysisResult.UnSurpinApneaCountIndex !== undefined ? analysisResult.UnSurpinApneaCountIndex : 'Processing'}</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> HI:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.UnSurpinHypopneaCountIndex !== null && analysisResult.UnSurpinHypopneaCountIndex !== undefined ? analysisResult.UnSurpinHypopneaCountIndex : 'Processing'}</td>
                      </tr>
                      <tr>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc' }}>Upright:</td>
                        {/* 非仰卧 */}
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Time - hr:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.totalUpRightTime} ({analysisResult.totalUpRightPercent}%)</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>REI (AHI):</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.UpRightReiCountIndex !== null && analysisResult.UpRightReiCountIndex !== undefined ? analysisResult.UpRightReiCountIndex : 'Processing'}</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> AI:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.UpRightApneaCountIndex !== null && analysisResult.UpRightApneaCountIndex !== undefined ? analysisResult.UpRightApneaCountIndex : 'Processing'}</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> HI:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.UpRightHypopneaCountIndex !== null && analysisResult.UpRightHypopneaCountIndex !== undefined ? analysisResult.UpRightHypopneaCountIndex : 'Processing'}</td>
                      </tr>
                      <tr>
                        <td colSpan="5" style={{ borderBottom: '1px solid #ccc' }}>Events totals:</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> Apneas:</td>
                        {/* Apneas: 呼吸暂停总次数，这里为9次。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}> {analysisResult.apneaCount !== null && analysisResult.apneaCount !== undefined ? analysisResult.apneaCount : 'Processing'}</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> Hypopneas:</td>
                        {/* Hypopneas: 低通气总次数，这里为68次。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}> {analysisResult.hypopneaCount !== null && analysisResult.hypopneaCount !== undefined ? analysisResult.hypopneaCount : 'Processing'}</td>
                      </tr>
                      {/* <tr>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc' }}>Apnea index:</td>
                        //Apnea index (呼吸暂停指数): 进一步细分呼吸暂停的类型，包括阻塞性（0.8）、中枢性（0.6）、混合性（0.0）和未分类（0.0）。 
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Obstructive:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>0 </td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Central:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>0</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> Mixed:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>0</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> Unclassified:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>0</td>
                      </tr>
                      <tr>
                        <td colSpan="5" style={{ borderBottom: '1px solid #ccc' }}>Cheyne-Stokes respiration:</td>
                        //Cheyne-Stokes respiration: 钟摆呼吸，是一种呼吸模式，表现为呼吸逐渐加深后又逐渐变浅，最后停止，然后循环重复。这里记录的时间为0小时，百分比为0%，意味着没有观察到此呼吸模式。 
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> Time - hr:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>0</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> Percentage:</td>
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>0</td>
                      </tr> */}
                      <tr>
                        <td style={{ height: '2em' }} colSpan="9"></td>
                      </tr>
                      <tr>
                        <td colSpan="5" style={{ borderBottom: '1px solid #ccc' }}>Oxygen desaturation:</td>
                        {/* Oxygen Desaturation (氧气饱和度下降) */}
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>ODI:</td>
                        {/* ODI (氧饱和度下降指数): 每小时睡眠期间氧饱和度下降3%或以上的次数，这里为11.2次。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.ODI}</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> Total:</td>
                        {/* Total (总数): 记录期间氧饱和度下降的总次数，这里为72次 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.ODITotal}</td>
                      </tr>
                      <tr>
                        <td colSpan="3" style={{ borderBottom: '1px solid #ccc' }}>Oxygen saturation %:</td>
                        {/* Oxygen Saturation % (氧气饱和度百分比) */}
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Baseline:</td>
                        {/* Baseline (基线): 平均氧饱和度的基线值，这里为97%。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.modeSPO2AsBaseline === 0 ? "No Valid Data" : analysisResult.modeSPO2AsBaseline} </td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Avg:</td>
                        {/* Avg (平均值): 平均氧饱和度百分比，这里为95%。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.averageSPO2 <= 0 ? "No Valid Data" : analysisResult.averageSPO2} </td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}> Lowest:</td>
                        {/* Lowest (最低值): 记录期间最低的氧饱和度百分比，这里为83%。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.minSPO2Value === Infinity || analysisResult.minSPO2Value === -Infinity ? 'No Valid Data' : analysisResult.minSPO2Value}</td>
                      </tr>
                      <tr>
                        <td colSpan="3" style={{}}>Oxygen saturation - eval time %:</td>
                        {/* Oxygen Saturation - Eval Time % (氧饱和度评估时间百分比) */}
                        <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>≤90%sat:</td>
                        {/* ≤90%sat: 氧饱和度低于90%的时间百分比，这里为1%。 */}
                        <td style={{ fontWeight: 'bold' }}> {isNaN(analysisResult.SPO2percentBelow90) ? 'No Valid Data' : analysisResult.SPO2percentBelow90} </td>
                        <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>≤85%sat:</td>
                        {/* ≤85%sat: 氧饱和度低于85%的时间百分比，这里为0%。 */}
                        <td style={{ fontWeight: 'bold' }}> {isNaN(analysisResult.SPO2percentBelow85) ? 'No Valid Data' : analysisResult.SPO2percentBelow85}</td>
                        <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>≤80%sat:</td>
                        {/* ≤80%sat: 氧饱和度低于80%的时间百分比，这里为0%。 */}
                        <td style={{ fontWeight: 'bold' }}> {isNaN(analysisResult.SPO2percentBelow80) ? 'No Valid Data' : analysisResult.SPO2percentBelow80}</td>
                      </tr>
                      <tr>
                        <td colSpan="6" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>≤88%sat:</td>
                        {/* ≤88%sat: 氧饱和度低于88%的时间百分比，也是0%。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}> {isNaN(analysisResult.SPO2percentBelow88) ? 'No Valid Data' : analysisResult.SPO2percentBelow88}</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>≤88%Time - hr:</td>
                        {/* ≤88%Time - hr: 氧饱和度低于88%的总时间，这里为0小时0分钟。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{analysisResult.SPO2below88TotalTimeHours}</td>
                      </tr>
                      <tr>
                        <td style={{ height: '2em' }} colSpan="9"></td>
                      </tr>
                      <tr>
                        <td colSpan="3" style={{ borderBottom: '1px solid #ccc' }}>Breaths:</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Total:</td>
                        {/* Total (总数): 总呼吸次数，这里为5324次。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{typeof analysisResult.totalBreaths === 'number' ? Math.round(analysisResult.totalBreaths) : analysisResult.totalBreaths} </td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Avg/min:</td>
                        {/*  (平均每分钟): 平均每分钟的呼吸次数，这里为13.9次。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{typeof analysisResult.totalBreaths === 'number' ? Math.round(analysisResult.averageBreathsPerMinute) : analysisResult.averageBreathsPerMinute}</td>
                        {/* <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Snores:</td>
                  // Snores (打鼾次数): 记录期间的打鼾总次数，这里为1296次。
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>0</td> */}
                      </tr>
                      <tr>
                        <td colSpan="3" style={{ borderBottom: '1px solid #ccc' }}>Snores:</td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Total:</td>
                        {/* Total (总数): 总呼吸次数，这里为5324次。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{typeof analysisResult.snoreCount === 'number' ? Math.round(analysisResult.snoreCount) : analysisResult.snoreCount} </td>
                        <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Avg/min:</td>
                        {/*  (平均每分钟): 平均每分钟的呼吸次数，这里为13.9次。 */}
                        <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{typeof analysisResult.snoreAvgmin === 'number' ? Math.round(analysisResult.snoreAvgmin) : analysisResult.snoreAvgmin}</td>
                        {/* <td colSpan="1" style={{ borderBottom: '1px solid #ccc', textAlignLast: 'right', paddingRight: '18px' }}>Snores:</td>
                  // Snores (打鼾次数): 记录期间的打鼾总次数，这里为1296次。
                  <td style={{ borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>0</td> */}
                      </tr>
                      <tr>
                        <td colSpan="3" style={{}}>Pulse - bpm:</td>
                        {/* Pulse - bpm (心率 - 每分钟心跳次数) */}
                        <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>Min:</td>
                        {/* Min (最小值): 最低心率，这里为55次/分钟。 */}
                        <td style={{ fontWeight: 'bold' }}>{analysisResult.minBPMValue === Infinity || analysisResult.minBPMValue === -Infinity ? 'No Valid Data' : analysisResult.minBPMValue} </td>
                        <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>Avg:</td>
                        {/* Avg (平均值): 平均心率，这里为69次/分钟。 */}
                        <td style={{ fontWeight: 'bold' }}>{isNaN(analysisResult.averageBPM) ? 'No Valid Data' : analysisResult.averageBPM}</td>
                        <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>Max:</td>
                        {/* Max (最大值): 最高心率，这里为97次/分钟。 */}
                        <td style={{ fontWeight: 'bold' }}>{analysisResult.maxBPMValue === Infinity || analysisResult.maxBPMValue === -Infinity ? 'No Valid Data' : analysisResult.maxBPMValue}</td>
                      </tr>
                      <tr>
                        <td colSpan="3" style={{}}>Body temperature °C:</td>

                        <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>Min:</td>

                        <td style={{ fontWeight: 'bold' }}>{analysisResult.minTempValue === Infinity || analysisResult.minTempValue === -Infinity ? 'No Valid Data' : analysisResult.minTempValue} </td>
                        <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>Avg:</td>

                        <td style={{ fontWeight: 'bold' }}>{isNaN(analysisResult.averageBodyTemp) ? 'No Valid Data' : analysisResult.averageBodyTemp}</td>
                        <td colSpan="1" style={{ textAlignLast: 'right', paddingRight: '18px' }}>Max:</td>

                        <td style={{ fontWeight: 'bold' }}>{analysisResult.maxTempValue === Infinity || analysisResult.maxTempValue === -Infinity ? 'No Valid Data' : analysisResult.maxTempValue}</td>
                      </tr>
                      <tr>
                        <td style={{ height: '2em' }} colSpan="9"></td>
                      </tr>
                      <tr>
                        <td colSpan="9" style={{}}>Analysis status: <span style={{ fontWeight: 'bold' }}>AI scoring</span></td>
                      </tr>
                      <tr>
                        <td colSpan="9" style={{}}>Analysis guidelines: <span style={{ fontWeight: 'bold' }}>AASM 2012</span></td>
                      </tr>
                      {/* <tr>
                  <td colSpan="9" style={{}}>Apnea[NaN%; NaNs; NaNs; NaNs; NaN%; NaN%; NaN%]; Hypopnea[NaN%; NaNs; NaNs; NaNs]; Snoring[NaN%; NaNs, NaNs; NaNs]; Desaturation[NaN%]; CSR[NaN]. Airflow sensor and respiratory effort sensor: Pressure transducer. Hypopneas were scored only if there was valid oximetry data.</td>
                </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div id='PDF2' style={{ position: 'relative' }}>
              <div style={{ display: 'flex' }}>
                <img src={MedicoLogo} alt="Medico Logo" style={{ height: '200px', marginRight: '40px', position: 'absolute', top: '50px', left: '20px' }} />
                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                    <div style={{ height: '300px', textAlign: 'right' }} >
                      <h2 style={{ textAlign: 'right', fontSize: '38px', color: '#0192d8' }}>
                        {userProfile.firstName + " " + userProfile.lastName}
                      </h2>
                      <h2 style={{ textAlign: 'right', fontSize: '32px' }}>{formatDate(Date.now())}</h2>
                    </div>
                  </div>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderTop: '2px solid black',
                    borderBottom: '2px solid black',
                    padding: '10px',
                    marginTop: '20px',
                    marginBottom: '50px'
                  }}>
                    <p style={{ fontSize: '28px', lineHeight: '8px' }}>
                      DOB: {formatBirthDate(userProfile.birthDate, userProfile.birthTimeZone)}
                    </p>
                    <p style={{ fontSize: '28px', lineHeight: '8px' }}>
                      Age: {calculateAge(userProfile.birthDate)}
                    </p>
                    <p style={{ fontSize: '28px', lineHeight: '8px' }}>
                      Gender: {userProfile.gender}
                    </p>
                    <p style={{ textAlign: 'right', fontSize: '28px', lineHeight: '8px' }}>
                      BMI: {userProfile.BMI != null ? userProfile.BMI : 'N/A'}
                    </p>
                  </div>
                  <h1 style={{
                    backgroundColor: '#0E4969',
                    color: 'white',
                    padding: '10px',
                    display: 'flex',
                    height: '75px',
                    justifyContent: 'space-between',
                    width: '100%',
                    boxSizing: 'border-box', // 包含 padding 在内
                    overflow: 'hidden',      // 防止内容溢出
                    whiteSpace: 'nowrap'     // 防止内容换行
                  }}>
                    <span>Interpretation</span>
                  </h1>
                  <div id="interpretation-display-view" style={{ fontSize: '20px', lineHeight: '1.5' }}>
                    {/* Cc: Sleep Report
                    <br />
                    Referring Physician: Dr. xxxx xxxx
                    <br />
                    Other Recipient:
                    <br /> */}
                    <br />
                    The overall Apnea/Hypopnea Index (AHI) was 0 events/hour.
                    <br />
                    The overall Oxygen Desaturation Index (ODI) was 0 events/hour with an Oxygen nadir of 0%.
                    <br />
                    <br />
                    <p>
                      Impression: <br />
                      Normal counts of oxygen desaturation during sleep. <br />
                      Mild snoring was documented.
                    </p>
                    <p>
                      Diagnosis:<br />
                      Based on this sleep study, evidence of significant sleep breathing disordered was not identified.<br />
                      Recommendations:<br />
                      Consider Dental Appliances or ENT procedures if otherwise indicated for snoring.<br />
                      Weight management and regular exercises should be initiated or continued.<br />
                      Positional therapy: avoiding supine posture during sleep.<br />
                      Sleep hygiene should be reviewed to assess factors that may improve sleep quality.<br />
                      Avoid alcohol, sedatives, or other depressants that may worsen snoring, trigger sleep apnea, and<br />
                      disrupt normal sleep architecture<br />
                    </p>
                    <p>
                      XXX XXXXXX,<br />
                      RPSGT,<br />
                      Registry No. XXXXX<br />
                      Electronically signed by XXXX XXXXX, NPI XXXXX<br />
                      {formatDate2(Date.now())}
                    </p>

                  </div>

                </div>

              </div>
            </div>
            <div id='PDF3' style={{ position: 'relative' }}>
              <div style={{ display: 'flex' }}>
                <img src={MedicoLogo} alt="Medico Logo" style={{ height: '200px', marginRight: '40px', position: 'absolute', top: '50px', left: '20px' }} />
                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                    <div style={{ height: '300px', textAlign: 'right' }} >
                      <h2 style={{ textAlign: 'right', fontSize: '38px', color: '#0192d8' }}>
                        {userProfile.firstName + " " + userProfile.lastName}
                      </h2>
                      <h2 style={{ textAlign: 'right', fontSize: '32px' }}>{formatDate(Date.now())}</h2>
                    </div>
                  </div>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderTop: '2px solid black',
                    borderBottom: '2px solid black',
                    padding: '10px',
                    marginTop: '20px',
                    marginBottom: '50px'
                  }}>
                    <p style={{ fontSize: '28px', lineHeight: '8px' }}>
                      DOB: {formatBirthDate(userProfile.birthDate, userProfile.birthTimeZone)}
                    </p>
                    <p style={{ fontSize: '28px', lineHeight: '8px' }}>
                      Age: {calculateAge(userProfile.birthDate)}
                    </p>
                    <p style={{ fontSize: '28px', lineHeight: '8px' }}>
                      Gender: {userProfile.gender}
                    </p>
                    <p style={{ textAlign: 'right', fontSize: '28px', lineHeight: '8px' }}>
                      BMI: {userProfile.BMI != null ? userProfile.BMI : 'N/A'}
                    </p>
                  </div>
                  {generatedAudioReport.apneaIMG && (
                    <div className="image-container" style={{ textAlign: "center" }}>
                      <img
                        src={generatedAudioReport.apneaIMG.imageUrl}  // 使用 imageUrl 渲染图片
                        alt={generatedAudioReport.apneaIMG.fileName}  // 使用 fileName 作为图片的 alt 文本
                        style={{
                          display: "block",
                          margin: "0 auto",  // 水平居中
                          width: "100%",     // 宽度占100%
                          marginBottom: "20px"  // 下方留空白
                        }}
                      />
                    </div>
                  )}
                  {generatedAudioReport.PNGFiles && generatedAudioReport.PNGFiles.length > 0 && (
                    <div className="image-container" style={{ textAlign: "center" }}>
                      {generatedAudioReport.PNGFiles.map(({ fileBlob, fileName }) => {
                        const imageUrl = window.URL.createObjectURL(fileBlob);

                        return (
                          <img
                            key={fileName}
                            src={imageUrl}
                            alt={fileName}
                            style={{
                              display: "block",
                              margin: "0 auto",  // 水平居中
                              width: "100%",     // 宽度占100%
                              marginBottom: fileName === 'plot_body_pos.png' ? "20px" : "0",  // 给上方图片下方留空白
                              marginTop: fileName === 'plot_spo2_hr.png' ? "20px" : "0"      // 给下方图片上方留空白
                            }}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>

    );
  }


}

export default DiagnoseReportPage;
