import React, { useRef, useEffect, useState } from 'react';
import static_BG from './assets/StatisticIMGs/static_BG.png';
import apneaIndexPng from './assets/StatisticIMGs/apneaIndex.png';

const ApneaIndexMergedImage = ({ analysisResult }) => {

  const bgImage = useRef(new Image());
  const arrowImage = useRef(new Image());
  const canvasRef = useRef(null);
  const [mergedImage, setMergedImage] = useState(null);
  const [apneaIndexValue, setApneaIndexValue] = useState(-2);
  useEffect(() => {
  bgImage.current.src = static_BG;
  arrowImage.current.src = apneaIndexPng;

  bgImage.current.onload = () => console.log('Background image cached');
  arrowImage.current.onload = () => console.log('Arrow image cached');

  bgImage.current.onerror = () => console.error('Failed to load background image');
  arrowImage.current.onerror = () => console.error('Failed to load arrow image');
}, []);
  useEffect(() => {
    if (!analysisResult || typeof analysisResult.reiIndex === 'undefined') {
      console.error('Invalid analysisResult:', analysisResult);
      return;
    }

    const validReiIndex =
      analysisResult.reiIndex !== null &&
        analysisResult.reiIndex !== undefined &&
        !isNaN(analysisResult.reiIndex)
        ? Math.min(Math.floor(analysisResult.reiIndex), 45) // 限制最大值为 45
        : -2;

    console.log('Setting validReiIndex to:', validReiIndex);

    if (apneaIndexValue !== validReiIndex) {
      setApneaIndexValue(validReiIndex);
    }
  }, [analysisResult, apneaIndexValue]);




  useEffect(() => {
    console.log('apneaIndexValue updated to:', apneaIndexValue);
  
    if (apneaIndexValue === null || isNaN(apneaIndexValue)) {
      console.error('Invalid apneaIndexValue:', apneaIndexValue);
      return;
    }
  
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error('Canvas element not found');
      return;
    }
  
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error('Failed to get canvas context');
      return;
    }
  
    // 确保背景图片和箭头图片加载完成后再绘制
    const handleImageLoad = () => {
      const margin = 20;
      const totalWidth = 1035;
      const maxApneaIndexValue = 45;
      const unitPixel = totalWidth / maxApneaIndexValue;
      const arrowPosition = apneaIndexValue * unitPixel + margin;
  
      canvas.width = bgImage.current.width + 2 * margin;
      canvas.height = bgImage.current.height + 50;
  
      ctx.drawImage(bgImage.current, margin, 0, bgImage.current.width, bgImage.current.height);
  
      const arrowWidth = arrowImage.current.width;
      const arrowHeight = arrowImage.current.height;
      const arrowX = arrowPosition - arrowWidth / 2;
      const arrowY = 0;
  
      ctx.drawImage(arrowImage.current, arrowX, arrowY, arrowWidth, arrowHeight);
  
      ctx.fillStyle = 'blue';
      ctx.font = 'bold 15px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
  
      const textX = arrowX + arrowWidth / 2;
      const textY = arrowY + arrowHeight / 2 - 3;
      ctx.fillText(apneaIndexValue.toString(), textX, textY);
  
      const dataURL = canvas.toDataURL('image/png');
      console.log('Generated dataURL:', dataURL);
      setMergedImage(dataURL);
    };
  
    // 判断图片是否已加载完成
    if (bgImage.current.complete && arrowImage.current.complete) {
      handleImageLoad();
    } else {
      bgImage.current.onload = handleImageLoad;
      arrowImage.current.onload = handleImageLoad;
    }
  }, [apneaIndexValue]);
  useEffect(() => {
    return () => {
      console.log('Cleaning up resources for canvas drawing');
      setMergedImage(null);
    };
  }, []);
  return (
    <div style={{ textAlign: 'center' }}>
      {/* 合成后的图片 */}
      {mergedImage ? (
        <img src={mergedImage} alt="Merged Apnea Index" style={{ width: '80%' }} />
      ) : (
        <p>Loading...</p>
      )}

      {/* 隐藏的 Canvas 用于生成图片 */}
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
    </div>
  );
};

export default ApneaIndexMergedImage;
